import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { Link, useStaticQuery, graphql } from 'gatsby'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '30vh',
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    textAlign: 'center;',
  },
  description: {
    fontSize: theme.spacing(1.5),
    color: '#a6a4a4',
  },
  copyright: {
    color: '#000000',
  },
}))

export default function StickyFooter() {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query FooterInfo {
      site {
        siteMetadata {
          title
          domain
        }
      }
    }
  `)

  const { domain } = data.site.siteMetadata

  return (
    <div className={classes.root}>
      <footer className={classes.footer}>
        <Container maxWidth='sm'>
          <Typography variant='body2' color='textSecondary'>
            Copyright ©{' '}
            <Link className={classes.copyright} to='/'>
              {domain}
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}{' '}
            <Link className={classes.copyright} to='/policy'>
              Privacy Policy
            </Link>{' '}
          </Typography>
        </Container>
        <Typography className={classes.description}>
          THE SOURCE OF THE DISPLAYED DATA IS EITHER THE PROPERTY OWNER OR //
          PUBLIC RECORD PROVIDED BY NON-GOVERNMENTAL THIRD PARTIES. IT IS //
          BELIEVED TO BE RELIABLE BUT NOT GUARANTEED. THIS INFORMATION IS //
          PROVIDED EXCLUSIVELY FOR CONSUMERS’ PERSONAL, NON-COMMERCIAL USE.
        </Typography>
      </footer>
    </div>
  )
}
