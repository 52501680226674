import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const useStyles = makeStyles((theme) => ({
  houseIcon: {
    marginRight: theme.spacing(2),
    flexGrow: 1,
  },
  title: {
    marginLeft: theme.spacing(2),
  },
  link: {
    color: 'black',
  },
}))

export default function Navbar() {
  const data = useStaticQuery(graphql`
    query LogoIcon {
      file(relativePath: { eq: "logo.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  const icon = getImage(data.file)

  const classes = useStyles()

  return (
    <div>
      <AppBar elevation={1} position='static'>
        <Toolbar>
          <Link className={classes.houseIcon} to='/'>
            <div>
              <GatsbyImage image={icon} alt='icon' />
            </div>
          </Link>
          <Typography className={classes.title} variant='h6'>
            <Link className={classes.link} to='/properties'>
              Houses
            </Link>
          </Typography>

          <Typography className={classes.title} variant='h6'>
            <Link className={classes.link} to='/about'>
              About
            </Link>
          </Typography>

          <Typography className={classes.title} variant='h6'>
            <Link className={classes.link} to='/contact'>
              Contact
            </Link>
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  )
}
