import React from 'react'
import Navbar from './navbar'
import '../styles/global.css'
import Footer from './footer'
import { createTheme, ThemeProvider, Container, Box } from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'

const theme = createTheme({
  palette: {
    primary: {
      main: '#fefefe',
    },
    secondary: {
      main: green[500],
    },
  },
  typography: {
    fontFamily: 'Quicksand',
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
})

export default function Layout({ children }) {
  const data = useStaticQuery(graphql`
    query Layout {
      site {
        siteMetadata {
          title
          domain
        }
      }
    }
  `)

  const { title } = data.site.siteMetadata
  return (
    <ThemeProvider theme={theme}>
      <Helmet title={title}>
        <link rel='shortcut icon' href='https://i.ibb.co/GRZ4tbp/favicon.png' />
        <script
          async
          src='https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7671971837675680'
          crossorigin='anonymous'
        ></script>
      </Helmet>
      <Container>
        <Navbar />
        <Box m={3}>{children}</Box>
        <Footer />
      </Container>
    </ThemeProvider>
  )
}
